import { SidepanelService } from './../../sidepanel/sidepanel.service'
import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CepService } from 'src/app/shared/services/cep/cep.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { FreemiumApiService } from 'src/app/shared/services/freemium-api/freemium-api.service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { UtmsService } from 'src/app/shared/services/utms/utms.service'
import { SidepanelComponent } from 'src/app/sidepanel/sidepanel.component'
import { AppComponent } from 'src/app/app.component'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { environment } from 'src/environments/environment'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { CpfCnpjValidator } from 'src/app/shared/validators/cpf-cnpj-validator'

@Component({
	selector: 'app-manual',
	templateUrl: './manual.component.html',
	styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
	public form: FormGroup
	public address: any
	public previousCep: string = ''
	public loading: boolean = false
	public ifood_frn_id: string
	public vendorId: string
	public cepInputed: boolean = false
	constructor(
		private loaderService: LoaderService,
		private cepService: CepService,
		private toastService: HotToastService,
		private storageService: StorageService,
		private router: Router,
		private utmsService: UtmsService,
		private crmApiService: CrmApiService,
		private freemiumApiService: FreemiumApiService,
		private cookieService: CookieService,
		private trackerService: TrackersService,
		private sidepanelService: SidepanelService,
		private mixPanelService: MixPanelService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.sidepanelService.setStep(4)
		this.initForm()
		this.checkDeal()
		this.vendorId = this.route.snapshot.queryParamMap.get('vendorId')
		this.ifood_frn_id = this.route.snapshot.queryParamMap.get('ifood_frn_id')
	}

	initForm() {
		const sessionForm = this.storageService.getForm()
		this.form = new FormGroup({
			document: new FormControl(sessionForm.document ?? '', [
				Validators.required,
				CpfCnpjValidator.validate
			]),
			cep: new FormControl('', [Validators.required, Validators.minLength(8)]),
			street: new FormControl(sessionForm?.address?.street ?? '', [
				Validators.required
			]),
			number: new FormControl(sessionForm?.address?.number ?? '', [
				Validators.required
			]),
			complement: new FormControl(sessionForm?.address?.complement ?? '', [
				Validators.maxLength(50)
			]),
			district: new FormControl(sessionForm?.address?.district ?? '', [
				Validators.required
			]),
			city: new FormControl(sessionForm?.address?.city ?? '', [
				Validators.required
			]),
			state: new FormControl(sessionForm?.address?.state ?? '', [
				Validators.required
			])
		})
	}
	async getCEP() {
		if (
			this.form.get('cep').value === '' ||
			this.form.get('cep').value.length < 8
		) {
			this.form.get('street').setValue('')
			this.form.get('district').setValue('')
			this.form.get('city').setValue('')
			this.form.get('state').setValue('')
			this.cepInputed = false
			return
		}
		this.loaderOn(true)
		try {
			const address = await this.cepService
				.getAddressByCep(this.form.get('cep').value)
				.catch((err) => {
					this.form.get('cep').setErrors({ CEPnotFounded: true })
					this.toastService.error('CEP não encontrado')
					this.cepInputed = false
					this.loaderOn(false)
					return
				})
			this.form.get('cep').setErrors(null)

			if (!address) return

			this.cepInputed = true
			this.form.markAllAsTouched()
			this.loaderOn(false)
			this.address = address
			this.form.get('street').setValue(address.street)
			this.form.get('district').setValue(address.neighboorhood)
			this.form.get('city').setValue(address.city)
			this.form.get('state').setValue(address.state)
		} catch (err) {
			this.loaderOn(false)
			this.toastService.error('CEP inválido')
		}
	}

	isInvalid() {
		return this.form.invalid
	}

	goBack() {
		this.sidepanelService.showManualStep(false)
		this.router.navigate(['/importation'], {
			queryParamsHandling: 'preserve'
		})
	}

	async submit() {
		if (this.form.invalid) return
		this.loaderOn(true)
		this.storageService.updateForm({
			document: this.form.get('document').value,
			address: {
				postalCode: this.form.get('cep').value,
				street: this.form.get('street').value,
				state: this.form.get('state').value,
				city: this.form.get('city').value,
				district: this.form.get('district').value,
				number: this.form.get('number').value,
				complement: this.form.get('complement').value
			}
		})

		const formData = this.storageService.getForm()

		const utms = this.utmsService.getUtms()
		const { moskitDealId, dealId } = this.storageService.getDeal()

		const data = {
			name: formData.name,
			cpf_cnpj: formData.document,
			email: formData.email,
			password: formData.password,
			page_name: formData.company,
			phone: formData.phone,
			crmId: moskitDealId,
			device_category: this.getDevice(),
			computer: formData.computer == true ? 'Sim' : 'Não',
			analytics_id: formData.analytics_id,
			ifood_frn_id: this.ifood_frn_id,
			vendorId: this.vendorId,
			ifood_registred: formData.ifood_registred,
			menu_import_by: 'NONE',
			address: {
				name: formData.address.street,
				num: formData.address.number,
				complement: formData.address.complement,
				neighborhood: formData.address.district,
				city: formData.address.city,
				state: formData.address.state,
				postal_code: formData.address.postalCode
			},
			...utms,
			admin_v2: 'NONE'
		}
		if (
			data.device_category == 'mobile' &&
			utms['utm_source'] != 'mobile-app' &&
			!utms.redirect
		) {
			let shouldBeCloudiaVar = false
			try {
				const { shouldBeCloudia } = await this.crmApiService.getIsCloudia()
				shouldBeCloudiaVar = shouldBeCloudia
			} catch {
				shouldBeCloudiaVar = true
			}
			if (
				shouldBeCloudiaVar ||
				utms.utm_source == 'teste2' ||
				utms.utm_source == 'teste4' ||
				utms.utm_source == 'anota-app-mobile-new'
			) {
				data['admin_v2'] = 'CLOUDIAv4'
				data['device_category'] = data['device_category'] + ' - CLOUDIAv4'
				data['bot_activation_type'] = 'CLOUDIA'
			} else {
				data['admin_v2'] = 'DEFAULT'
				data['device_category'] = data['device_category'] + ' - DEFAULT'
			}
		}

		Object.keys(data).forEach((key) =>
			data[key] == null || data[key] == 'undefined' ? delete data[key] : {}
		)

		this.freemiumApiService.createCompany(data).subscribe(
			(res: any) => {
				if (!res.success) {
					if (res.emailExists) {
						this.router.navigate(['/email-sent'], {
							queryParamsHandling: 'preserve'
						})
						this.trackerService.track('EMAIL-ALREADY-REGISTERED')
						this.loaderOn(false)
						return
					}
					this.loaderOn(false)
					this.toastService.error(
						'Ocorreu um erro ao cadastrar seu restaurante, tente novamente mais tarde. ',
						res.message
					)
					return
				}
				let sessionForm = this.storageService.getForm()
				this.trackerService.track('CREATE-COMPANY-MANUAL')
				const $crawlerObs = this.freemiumApiService
					.getCompanyImportStatus(res.data.menuId)
					.subscribe((res: any) => {
						if (res.data.state === 'error') {
							this.loaderOn(false)
							this.form.enable()
							$crawlerObs.unsubscribe()
							this.toastService.error(res.data.message)
							return
						}
						if (res.data.state === 'completed') {
							$crawlerObs.unsubscribe()

							let expiryDate = new Date()
							expiryDate.setMonth(expiryDate.getMonth() + 2)

							this.cookieService.set(
								'ADMIN_TOKEN',
								JSON.stringify(res.data.token),
								expiryDate,
								'/'
							)

							this.loaderOn(false)
							this.trackerService.track('MANUAL-IMPORT', sessionForm)
							this.trackerService.track('ACCOUNT-CREATED', sessionForm)

							this.mixPanelService.track(
								'manual-register-done',
								formData.analytics_id
							)

							if (utms.redirect) {
								console.log('Redirecting to: ', utms.redirect)
								window.location.href = utms.redirect
								return
							}

							if (data.admin_v2 == 'CLOUDIAv4') {
								window.location.href = `${environment.ANOTAAI_ADMIN_URL}/access/${res.data.token}`
								return
							}

							this.router.navigate(['/thanks'], {
								queryParamsHandling: 'preserve'
							})

							return
						}
					})
			},
			(err) => {
				this.loaderOn(false)
				this.toastService.error(
					'Ocorreu um erro ao cadastrar seu restaurante, tente novamente mais tarde',
					err.message
				)
				return
			}
		)
	}

	public getDevice() {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return 'mobile'
		} else {
			return 'desktop'
		}
	}

	loaderOn(val: boolean) {
		this.loading = val
	}
	public async checkDeal(): Promise<void> {
		const { dealId } = this.storageService.getDeal()
		if (dealId) {
			const $req = this.crmApiService.getDeal(dealId).subscribe(
				(res: any) => {
					const { data: deal } = res
					this.storageService.updateForm({
						name: deal.name,
						phone: deal.contacts[0].phone,
						email: deal.contacts[0].email,
						company: deal.companies[0].name
					})

					const analyticsId = res.data.customFields.find(
						(field) => field.key === environment.UUID_KEY
					).value

					this.storageService.updateForm({ analytics_id: analyticsId })
				},
				(err) => {},
				() => {
					$req.unsubscribe()
				}
			)

			try {
				const { data } = await this.crmApiService.getDeal(dealId).toPromise()

				this.storageService.setDeal({
					dealId: data._id,
					moskitDealId: data._id
				})
			} catch (error) {
				this.storageService.setDeal()
				this.router.navigate(['/'], {
					queryParamsHandling: 'preserve'
				})
			}
			return
		}

		this.router.navigate(['/'], {
			queryParamsHandling: 'preserve'
		})
	}
}
