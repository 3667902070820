import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { environment } from 'src/environments/environment'
import { CredentialsComponent } from './pages/credentials/credentials.component'
import { ImportationComponent } from './pages/importation/importation.component'
import { InformationsComponent } from './pages/informations/informations.component'
import { ManualComponent } from './pages/manual/manual.component'
import { NextStepsComponent } from './pages/next-steps/next-steps.component'
import { RemarketingComponent } from './pages/remarketing/remarketing.component'
import { ThanksComponent } from './pages/thanks/thanks.component'
import { RedirectGuard } from './shared/guards/redirect.guard'
import { EmailSentComponent } from './pages/email-sent/email-sent.component'

const routes: Routes = [
	{
		path: 'remarketing',
		component: RemarketingComponent
	},
	{
		path: 'informations',
		component: InformationsComponent
	},
	{
		path: 'credentials',
		component: CredentialsComponent
	},
	{
		path: 'importation',
		component: ImportationComponent
	},
	{
		path: 'manual',
		component: ManualComponent
	},
	{
		path: 'thanks',
		component: ThanksComponent
	},
	{
		path: 'next-step',
		component: NextStepsComponent
	},
	{
		path: 'email-sent',
		component: EmailSentComponent
	},
	{
		path: 'checkout',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: { externalUrl: `${environment.REDIRECT_URL}/checkout` }
	},
	{
		path: 'onboarding',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: { externalUrl: `${environment.REDIRECT_URL}/onboarding` }
	},
	{
		path: 'ifood',
		loadChildren: () => import('./pages/vitrine/vitrine.module').then(m => m.VitrineModule),
	},
	{
		path: '',
		redirectTo: 'informations',
		pathMatch: 'full'
		// preserve queries
	},
	{
		path: '**',
		redirectTo: 'informations'
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
