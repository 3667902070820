import { SidepanelService } from './sidepanel.service'
import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-sidepanel',
	templateUrl: './sidepanel.component.html',
	styleUrls: ['./sidepanel.component.scss']
})
export class SidepanelComponent implements OnInit {
	public availableSteps: any = [
		{
			path: 'informations',
			title: 'Informações da Conta',
			step: 1,
			display: true
		},
		{ path: 'credentials', title: 'Credenciais', step: 2, display: true },
		{ path: 'importation', title: 'Importar Cardápio', step: 3, display: true }
	]


	public availableStepsBackup = this.availableSteps.slice()

	public manualStep = {
		path: 'manual',
		title: 'Preenchimento Manual',
		step: 4,
		display: true
	}

	public vitrineSteps:any = [{
		path: 'ifood',
		title: 'Informações da Conta',
		step: 1,
		display: true
	},
	{
		path: 'ifood/download',
		title: 'Download',
		step: 2,
		display: true
	}]

	@Input()
	public currentStep: any = this.availableSteps[0]

	constructor(
		private sidepanelService: SidepanelService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.sidepanelService.getStep().subscribe((step) => {
			this.currentStep = step
		})

		this.sidepanelService.showManualStepObs().subscribe((showManual) => {
			if (showManual && !this.availableSteps.includes(this.manualStep)) {
				this.availableSteps.push(this.manualStep)
			} else if (!showManual) {
				this.availableSteps = this.availableSteps.filter(
					(step) => step.step != 4
				)
			}
		})

		this.sidepanelService.isVitrineObs().subscribe((isVitrine) => {
			if (isVitrine) {
				this.availableSteps = this.vitrineSteps
			} else {
				this.availableSteps = this.availableStepsBackup
			}
		})
	}

	goTo(path: string) {
		this.router.navigate([path], {
			queryParamsHandling: 'preserve'
		})
	}
		
}
