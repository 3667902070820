import { CookieService } from 'ngx-cookie-service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtmsService } from '../../services/utms/utms.service';

@Component({
	selector: 'app-waiter',
	templateUrl: './waiter.component.html',
	styleUrls: ['./waiter.component.scss']
})
export class WaiterComponent implements OnInit {

	@Input() form: FormGroup
	@Output() nextStep = new EventEmitter<void>()

	public waiterQuantityOptions = [
		{
			label: 'Menos de 3',
			value: 'Menos de 3'
		},
		{
			label: 'De 3 a 5',
			value: 'De 3 a 5'
		},
		{
			label: 'Mais de 5',
			value: 'Mais de 5'
		}]

	public waiterTablesOptions = [
		{
			label: 'Menos de 15',
			value: 'Menos de 15'
		},
		{
			label: 'De 15 a 30',
			value: 'De 15 a 30'
		},
		{
			label: 'Mais de 30',
			value: 'Mais de 30'
		}]

	public waiterQuantity = { value: null, label: null }
	public waiterTables = { value: null, label: null }

	public tablesNotSelected = ''
	public quantityNotSelected = ''


	constructor(
		private cookieService: CookieService,
		private utmsService: UtmsService
	) { }

	ngOnInit(): void {
		this.populateFields()
	}

	populateFields() {
		const { waiter_quantity, waiter_tables } = this.utmsService.getUtms()
		waiter_quantity && this.selectOption(this.waiterQuantityOptions.find(option => option.value === waiter_quantity))
		waiter_tables && this.selectWaiterTables(this.waiterTablesOptions.find(option => option.value === waiter_tables))
	}

	selectOption(option) {
		this.waiterQuantity = option
		this.form.get('waiter_quantity').setValue(this.waiterQuantity.value)
		this.cookieService.set('waiter_quantity', this.waiterQuantity.value)
		this.emmitEvent()
	}

	selectWaiterTables(option) {
		this.waiterTables = option
		this.form.get('waiter_tables').setValue(this.waiterTables.value)
		this.cookieService.set('waiter_tables', this.waiterTables.value)
		this.emmitEvent()	
	}

	emmitEvent(){
		this.nextStep.emit()
	}

}
