<main>
    <img class="logo" src="../../../assets/logo.svg" />
    <h2 class="success-title">Parabéns :)<br />Primeira etapa concluída!</h2>
    <p class="next-step-text">
        O próximo passo é atender todos os seus clientes em poucos segundos e não
        perder mais pedidos.<br />{{
        isRegisterInAppMobile
        ? ''
        : 'Baixe o app agora mesmo!'
        }}
    </p>
    <anota-button (click)="downloadBotMobile()">{{
        isRegisterInAppMobile
        ? 'Começar a usar agora'
        : 'Fazer download do app'
        }}
    </anota-button>
    <div class="bot-video-container">
        <iframe src="https://www.youtube.com/embed/HVOYFGMK7cc" title="Aplicativo para Celular | Anota AI"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
    <div class="faq">
        <h3>Perguntas Frequentes</h3>
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let question of faq">
                <mat-expansion-panel-header>
                    <mat-panel-title> {{question.question}} </mat-panel-title>
                </mat-expansion-panel-header>
                <span [innerHTML]="question.answer"></span>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</main>