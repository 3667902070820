import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root'
})
export class SidepanelService {
	private stepSubject = new BehaviorSubject<Number>(1)
	private showSubject = new BehaviorSubject<any>(false)

	private isVitrine = new BehaviorSubject<boolean>(false)

	constructor() {}

	getStep() {
		return this.stepSubject.asObservable()
	}

	setStep(step: number) {
		this.stepSubject.next(step)
		if (step == 4) {
			this.showManualStep(true)
		}
	}

	showManualStep(show: boolean) {
		this.showSubject.next(show)
	}

	showManualStepObs() {
		return this.showSubject.asObservable()
	}

	changeToVitrine(state: boolean) {
		this.isVitrine.next(state)
	}

	isVitrineObs() {
		return this.isVitrine.asObservable()
	}

}
