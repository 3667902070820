<main>
    <section>
        <img class="logo" src="../../../assets/logo.svg" />
        <h2>
            Nosso aplicativo é compatível apenas com Andorid.
        </h2>
        <br />
        <div class="android-logo">
            <ul class="andy">
                <li class="head anim-head">
                    <ul class="antennas">
                        <li class="left-antenna anim-l-ant"></li>
                        <li class="right-antenna anim-r-ant"></li>
                    </ul>
                    <ul class="eyes anim-eyes">
                        <li class="left-eye anim-l-eye"></li>
                        <li class="right-eye anim-r-eye"></li>
                    </ul>
                </li>
            </ul>
        </div>
        <br />
        <p>
            Enviamos uma mensagem para seu email para que você possa continuar
            através de um dispositivo Android.
        </p>
    </section>
</main>