import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { environment } from 'src/environments/environment'

const BOT_MOBILE_URL = environment.BOT_MOBILE_URL

declare global {
	interface Window {
		dataLayer: any
	}
}

@Component({
	selector: 'app-bot-mobile-supported-os',
	templateUrl: './bot-mobile-supported-os.component.html',
	styleUrls: ['./bot-mobile-supported-os.component.scss']
})
export class BotMobileSupportedOsComponent implements OnInit {
	public faq = [
		{
			question: 'Como funciona o robô?',
			answer:
				'Após criar sua conta, instalar e ativar o seu robô, o atendente virtual da Anota AI irá atender todos os clientes do seu WhatsApp ao mesmo tempo de forma automática, enviando seu cardápio digital e também, sendo capaz de tirar as principais dúvidas dos seus clientes (horário de funcionamento, taxa de entrega, promoções…), além realizar a atualização do status dos seus pedidos de forma automática.'
		},
		{
			question: 'Como faço para instalar o robô?',
			answer:
				'Primeiramente você deverá acessar o aplicativo na sua Play Store acessando o <a href="https://play.google.com/store/apps/details?id=ai.anota.android" target="_blank">link</a>, em seguida, clique em “Instalar”. Após baixar o aplicativo, basta conceder as permissões solicitadas e fazer o login com o e-mail e senha que você cadastrou em sua conta.'
		},
		{
			question: 'Como faço para ativar o robô?',
			answer:
				'Após criar sua conta, instalar o <a href="https://play.google.com/store/apps/details?id=ai.anota.android" target="_blank">aplicativo</a> e fazer o login, você irá clicar em “Ative seu robô”. Agora, basta permitir que o app Anota AI acesse suas notificações e em seguida nosso robô irá começar a responder seus clientes. Lembrando que para que seu robô funcione você deverá manter o WhatsApp fechado e seu aplicativo Anota AI funcionando.'
		},
		{
			question: 'Como acompanhar meus pedidos?',
			answer:
				'Para acompanhar seus pedidos basta clicar no ícone de painel que fica na barra inferior do seu aplicativo. Ao clicar você poderá visualizar seu painel de pedidos, que te propiciará avançar pedidos para a fase de produção ou entrega, além de ter acesso a toda a aba lateral de ferramentas do sistema.'
		}
	]

	public isRegisterInAppMobile = false

	constructor(
		private cookieService: CookieService,
		private route: ActivatedRoute,
		private trackersService: TrackersService,
		private storageService: StorageService
	) {}

	ngOnInit(): void {
		const token = this.route.snapshot.queryParamMap.get('token')
		if (token) {
			let expiryDate = new Date()
			expiryDate.setMonth(expiryDate.getMonth() + 2)
			this.cookieService.set(
				'ADMIN_TOKEN',
				JSON.stringify(token),
				expiryDate,
				'/'
			)
		}
		this.isRegisterInAppMobile = /anotaaibot/i.test(navigator.userAgent)
	}

	downloadBotMobile() {
		window.dataLayer.push({
			event: 'downloadBotMobile'
		})
		const userAgent = navigator.userAgent.toLowerCase()

		let sessionForm = this.storageService.getForm()

		this.trackersService.track("DOWNLOAD-BOT-MOBILE", sessionForm)

		if (this.isRegisterInAppMobile) {
			const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')
			window.open(`${BOT_MOBILE_URL}/access/${token}`, '_self')
			return
		}

		const isAndroid = userAgent.indexOf('android') > -1
		if (isAndroid) {
			window.open('https://instalar.anota.ai/aplicativo', '_blank')
			return
		}
		window.open(
			'https://play.google.com/store/apps/details?id=ai.anota.android&hl=pt_BR',
			'_blank'
		)
	}
}
