<div>
    <h2>Quantas mesas você possuí no seu estabelecimento?</h2>
    <h3>
        Isso não mudará nosso período de avaliação, mas nos ajudará a fornecer os materiais certos para que você aumente
        a eficiência da sua operação.
    </h3>
    <mat-button-toggle-group  multiple="false" [value]="waiterTables.value">
        <mat-button-toggle *ngFor="let waiterTable of waiterTablesOptions"
            [ngClass]="waiterTable.value !== waiterTables.value? tablesNotSelected : ''" [value]="waiterTable.value"
            (click)="selectWaiterTables(waiterTable)">{{waiterTable.label}}</mat-button-toggle>
    </mat-button-toggle-group>
    <br />
    <h2>Quantos garçons trabalham em seu estabelecimento?</h2>
    <h3>
        Essa informação é essencial para que possamos entender melhor suas necessidades.
    </h3>
    <mat-button-toggle-group class="pc" multiple="false" [value]="waiterQuantity.value">
        <mat-button-toggle *ngFor="let waiter of waiterQuantityOptions"
            [ngClass]="waiter.value !== waiterQuantity.value? quantityNotSelected : ''" [value]="waiter.value"
            (click)="selectOption(waiter)">{{waiter.label}}</mat-button-toggle>
        <br />
    </mat-button-toggle-group>
    <br />
</div>