import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { SidepanelComponent } from './sidepanel/sidepanel.component'

import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'

import { NgxMaskModule } from 'ngx-mask'
import { CookieService } from 'ngx-cookie-service'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { DsModule } from '@githubanotaai/design-system'
import { InformationsComponent } from './pages/informations/informations.component'
import { HttpClientModule } from '@angular/common/http'
import { HotToastModule } from '@ngneat/hot-toast'
import { CredentialsComponent } from './pages/credentials/credentials.component'
import { ImportationComponent } from './pages/importation/importation.component'
import { LoaderComponent } from './shared/components/loader/loader.component'
import { PulseLoaderComponent } from './shared/components/pulse-loader/pulse-loader.component'
import { ThanksComponent } from './pages/thanks/thanks.component'
import { ManualComponent } from './pages/manual/manual.component'
import { NextStepsComponent } from './pages/next-steps/next-steps.component'
import { InputComponent } from './shared/components/input/input.component'

import { environment } from 'src/environments/environment'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'
import { RemarketingComponent } from './pages/remarketing/remarketing.component'
import { RedirectGuard } from './shared/guards/redirect.guard'
import { AnotaaiDesktopSupportedOsComponent } from './pages/thanks/components/anotaai-desktop-supported-os/anotaai-desktop-supported-os.component'
import { AnotaaiDesktopUnsupportedOsComponent } from './pages/thanks/components/anotaai-desktop-unsupported-os/anotaai-desktop-unsupported-os.component'
import { BotMobileSupportedOsComponent } from './pages/thanks/components/bot-mobile-supported-os/bot-mobile-supported-os.component'
import { BotMobileUnsupportedOsComponent } from './pages/thanks/components/bot-mobile-unsupported-os/bot-mobile-unsupported-os.component'
import { AccordianComponent } from './pages/next-steps/components/accordian/accordian.component'
import { BotMobileOneLinkComponent } from './pages/thanks/components/bot-mobile-one-link/bot-mobile-one-link.component'
import { VitrineModule } from './pages/vitrine/vitrine.module';
import { EmailSentComponent } from './pages/email-sent/email-sent.component';
import { WaiterComponent } from './shared/components/waiter/waiter.component'

@NgModule({
	declarations: [
		AppComponent,
		SidepanelComponent,
		InformationsComponent,
		CredentialsComponent,
		ImportationComponent,
		LoaderComponent,
		PulseLoaderComponent,
		ThanksComponent,
		ManualComponent,
		RemarketingComponent,
		AnotaaiDesktopSupportedOsComponent,
		AnotaaiDesktopUnsupportedOsComponent,
		BotMobileSupportedOsComponent,
		BotMobileUnsupportedOsComponent,
		NextStepsComponent,
		AccordianComponent,
		InputComponent,
		BotMobileOneLinkComponent,
  		EmailSentComponent,
    WaiterComponent,
	],
	imports: [
		BrowserModule,
		MatIconModule,
		MatExpansionModule,
		AppRoutingModule,
		DsModule,
		MatButtonToggleModule,
		NgxMaskModule.forRoot(),
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		HotToastModule.forRoot(),
		GoogleTagManagerModule.forRoot({
			id: environment.TAG_MANAGER_ID
		}),
		VitrineModule,
	],
	providers: [SidepanelComponent, RedirectGuard],
	bootstrap: [AppComponent]
})
export class AppModule {}
