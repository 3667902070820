<div class="container">
	<form [formGroup]="form" class="form" (submit)="submit()">
		<div class="header-cep">
			<h1 style="margin: 0">Este é nosso último passo</h1>
			<h3 style="margin: 0">
				Digite o CEP do seu estabelecimento para te ajudarmos a preencher o
				endereço
			</h3>
			<span class="helper">
				<a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank"><i>Não sei meu
						CEP</i></a>
			</span>
		</div>
		<br />
		<app-input class="w-full" label="CEP *" placeholder="99999-999" type="text" [control]="form.controls.cep"
			[mask]="'00000-000'" (keyup)="getCEP()"></app-input>
		<div [hidden]="!cepInputed">

			<app-input class="w-full" label="Logradouro *" type="text" [control]="form.controls.street"></app-input>

			<div class="row">
				<app-input class="col-sm" label="Número *" type="text" placeholder="Ex: 15B"
					[control]="form.controls.number"></app-input>
				<app-input class="col-lg" label="Complemento" type="text" placeholder="Ex: Casa 01"
					[control]="form.controls.complement"></app-input>
			</div>

			<div class="row">
				<app-input class="col-lg" label="Bairro *" type="text" [control]="form.controls.district"></app-input>
				<app-input class="col-sm" label="Estado *" type="text" [control]="form.controls.state"></app-input>

			</div>

			<app-input class="w-full" label="Cidade *" type="text" [control]="form.controls.city"></app-input>

			<app-input type="text" placeholder="Insira o seu CPF ou CNPJ" label="CPF ou CNPJ *" mask="CPF_CNPJ"
				[control]="form.controls.document"></app-input>
		</div>

		<div class="end-buttons">
			<anota-button size="lg" [disabled]="form.invalid" *ngIf="!loading">Finalizar cadastro</anota-button>
			<anota-button [disabled]="true" *ngIf="loading"><svg class="spinner" viewBox="0 0 50 50">
					<circle class="path" cx="25" cy="25" r="10" fill="none" stroke-width="5"></circle>
				</svg></anota-button>
			<anota-button size="lg" (click)="goBack()" type="outline-primary">Voltar</anota-button>
		</div>
	</form>
</div>