<div class="navigation-container">
	<div class="navigation-content">
		<img src="../../assets/logo-anota-ai.png">
		<div *ngFor="let step of availableSteps">
			<div *ngIf="step.display">
				<div [ngClass]="currentStep == step.step? 'selected-item' : 'navigation-item'"
					(click)="goTo(step.path)">
					<span class="marker"></span>
					<p>{{step.step}}. {{step.title}}</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="mobile-stepper">
	<div class="navigation-content">
		<div class="logo">
			<img src="../../assets/logo-anota-ai.png">
		</div>

		<div>
			<p>passo {{currentStep}} de {{availableSteps.length}}</p>
		</div>
	</div>
</div>