import { Injectable } from '@angular/core';
import { CustomWindow } from './interfaces/custom-window';

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {

  constructor() { }

  private getWindow(): any {
    return window;
  }

  getNativeWindow(): CustomWindow {
    return this.getWindow();
  }
}