<main>
    <section>
        <h2>Iremos te ajudar a fazer o seu login. Não saia desta página! 😀</h2>
        <p>
            Após a instalação do aplicativo, volte aqui para ver o passo a passo de
            como entrar na plataforma.
        </p>
        <app-pulse-loader info=" Aguardando a instalação..."></app-pulse-loader>
        <br />
        <p>
            Está com problemas para baixar nosso gerenciador de pedidos?
            <br />
            <a (click)="downloadAnotaAI()">Tente novamente</a>
        </p>
    </section>
</main>
<div class="download-arrow" *ngIf="!isMobileBrowser">
    <img src=" assets/download-arrow.svg" />
</div>