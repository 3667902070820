import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'

export interface UTM {
	utm_source?: string
	utm_medium?: string
	utm_campaign?: string
	utm_term?: string
	utm_content?: string
	application?: string
	partner?: string
	gclid?: string
	fbclid?: string
	ttclid?: string
	redirect_by?: string
	ifood_merchant_id?: string
	indicado_aid?: string
	terceirizado?: string
	freemium?: string
	admin_v2?: string
	ref?: string
	redirect?: string
	waiter_quantity?: string
	waiter_tables?: string
	ab_test_mkt?: string
	msclkid?: string
	epik?: string
	click_id?: string
	bid?: string
	has_waiter?:string
}

@Injectable({
	providedIn: 'root'
})
export class UtmsService {
	constructor(
		private cookieService: CookieService,
		private route: ActivatedRoute
	) {}

	public setUtmCookies() {
		const utms = this.route.snapshot.queryParams
		const utmCookies = this.cookieService.getAll()
		const data: UTM = {
			utm_source: utms.utm_source || utmCookies.utm_source,
			utm_medium: utms.utm_medium || utmCookies.utm_medium,
			utm_campaign: utms.utm_campaign || utmCookies.utm_campaign,
			utm_term: utms.utm_term || utmCookies.utm_term,
			utm_content: utms.utm_content || utmCookies.utm_content,
			application: utms.application || utmCookies.application,
			partner: utms.partner || utmCookies.partner,
			gclid: utms.gclid || utmCookies.gclid,
			fbclid: utms.fbclid || utmCookies.fbclid,
			ttclid: utms.ttclid || utmCookies.ttclid,
			redirect_by: utms.redirect_by || utmCookies.redirect_by,
			ifood_merchant_id: utms.ifood_merchant_id || utmCookies.ifood_merchant_id,
			indicado_aid: utms.indicado_aid || utmCookies.indicado_aid,
			terceirizado: utms.terceirizado || utmCookies.terceirizado,
			freemium: utms.freemium || utmCookies.freemium,
			admin_v2: utms.admin_v2 || utmCookies.admin_v2,
			ref: utms.ref || utmCookies.ref,
			redirect: utms.redirect || utmCookies.redirect,
			waiter_quantity: utms.waiter_quantity || utmCookies.waiter_quantity,
			waiter_tables: utms.waiter_tables || utmCookies.waiter_tables,
			ab_test_mkt: utms.ab_test_mkt || utmCookies.ab_test_mkt,
			msclkid: utms.msclkid || utmCookies.msclkid,
			epik: utms.epik || utmCookies._epik || utms._epik || utmCookies.epik,
			click_id: utms.click_id || utmCookies.click_id,
			bid: utms.bid || utmCookies.bid,
			has_waiter: utms.has_waiter || utmCookies.has_waiter,
		}
		this.cookieService.set('utm_source', data.utm_source)
		this.cookieService.set('utm_medium', data.utm_medium)
		this.cookieService.set('utm_campaign', data.utm_campaign)
		this.cookieService.set('utm_content', data.utm_content)
		this.cookieService.set(
			'application',
			data.application,
			new Date(new Date().getTime() + 60 * 60 * 1000)
		)
		this.cookieService.set('utm_term', data.utm_term)
		this.cookieService.set('partner', data.partner)
		this.cookieService.set('gclid', data.gclid)
		this.cookieService.set('fbclid', data.fbclid)
		this.cookieService.set('ttclid', data.ttclid)
		this.cookieService.set('redirect_by', data.redirect_by)
		this.cookieService.set('ifood_merchant_id', data.ifood_merchant_id)
		this.cookieService.set('indicado_aid', data.indicado_aid)
		this.cookieService.set('terceirizado', data.terceirizado)
		this.cookieService.set('freemium', data.freemium)
		this.cookieService.set('admin_v2', data.admin_v2)
		this.cookieService.set('ref', data.ref)
		this.cookieService.set('redirect', data.redirect)
		this.cookieService.set('waiter_quantity', data.waiter_quantity)
		this.cookieService.set('waiter_tables', data.waiter_tables)
		this.cookieService.set('ab_test_mkt', data.ab_test_mkt)
		this.cookieService.set('msclkid', data.msclkid)
		this.cookieService.set('_epik', data.epik)
		this.cookieService.set('click_id', data.click_id)
		this.cookieService.set('bid', data.bid)
		this.cookieService.set('has_waiter', data.has_waiter)
	}

	public getUtms(): UTM {
		const utmCookies = this.cookieService.getAll()
		const utm = {
			utm_source: utmCookies.utm_source,
			utm_medium: utmCookies.utm_medium,
			utm_campaign: utmCookies.utm_campaign,
			utm_term: utmCookies.utm_term,
			utm_content: utmCookies.utm_content,
			application:
				utmCookies.utm_source == 'mobile-app' ||
				utmCookies.utm_source == 'anota-app-mobile-new'
					? 'BOT_MOBILE'
					: utmCookies.application,
			partner: utmCookies.partner,
			gclid: utmCookies.gclid,
			fbclid: utmCookies.fbclid,
			ttclid: utmCookies.ttclid,
			redirect_by: utmCookies.redirect_by,
			ifood_merchant_id: utmCookies.ifood_merchant_id,
			indicado_aid: utmCookies.indicado_aid,
			terceirizado: utmCookies.terceirizado,
			freemium: utmCookies.freemium,
			admin_v2: utmCookies.admin_v2,
			// ref: utmCookies.ref,
			redirect: utmCookies.redirect,
			waiter_quantity: utmCookies.waiter_quantity,
			waiter_tables: utmCookies.waiter_tables,
			ab_test_mkt: utmCookies.ab_test_mkt,
			msclkid: utmCookies.msclkid,
			epik: utmCookies._epik,
			click_id: utmCookies.click_id,
			bid: utmCookies.bid,
			has_waiter: utmCookies.has_waiter,
		}

		Object.keys(utm).forEach((key) => {
			if (utm[key] && utm[key].includes(',')) {
				utm[key] = utm[key].slice(0, utm[key].indexOf(','))
			}
		})

		// remove utm if it's empty, null, undefined, "null", "undefined"
		Object.keys(utm).forEach((key) => {
			if (
				!utm[key] ||
				utm[key] === '' ||
				utm[key] === null ||
				utm[key] === undefined ||
				utm[key] === 'null' ||
				utm[key] === 'undefined'
			) {
				delete utm[key]
			}
		})

		return utm
	}
}
