import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable, map, retryWhen, delayWhen, throwError, timer } from 'rxjs'
import { StorageService } from '../storage/storage.service'

@Injectable({
	providedIn: 'root'
})
export class CrmApiService {
	private baseUrl = environment.CRM_API_URL
	private proxyUrl = environment.INBOUND_URL
	public shareableCode: string
	public analyticsId: string
	constructor(
		private http: HttpClient,
		private storageService: StorageService
	) {}

	public upsertDeal(deal, isTrial = false) {
		let endString = ''
		this.shareableCode
			? (endString = `&shareableCode=${this.shareableCode}`)
			: (endString = '')
		return this.http.post(
			`${this.proxyUrl}/deals?isTrial=${isTrial}${endString}`,
			deal
		)
	}

	public generateAnalyticsId(): any {
		this.http.get(`${this.baseUrl}/noauth/new-uuid`).subscribe((res: any) => {
			this.analyticsId = res.data
			this.storageService.updateForm({ analytics_id: this.analyticsId })
			return this.analyticsId
		})
	}

	public getAnalyticsId() {
		if (!this.analyticsId) {
			this.generateAnalyticsId()
		}
		return this.analyticsId
	}

	public setAnalyticsId(analyticsId) {
		this.analyticsId = analyticsId
	}

	getDeal(dealId: string) :any{
		return this.http.get(`${this.baseUrl}/deals/${dealId}`)
	}

	updateDealStep(dealId: any, step: string) {
		return this.http.put(`${this.baseUrl}/deals/${dealId}`, {
			step: {
				current_step: step
			}
		})
	}

	getIsCloudia(value?):any {
		return this.http.get(`${this.proxyUrl}/v1/cloudia-rng${value ? '?value='+value : '' }`).toPromise()
	}
	
}
