import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root'
})
export class CepService {
	constructor(private http: HttpClient) {}

	async getAddressByCep(cep: string): Promise<any> {
		const response = await this.http
			.get<any>(`https://viacep.com.br/ws/${cep}/json/`)
			.toPromise()

		if (response.erro) {
			throw new Error('CEP não encontrado')
		}

		return {
			street: response.logradouro,
			zipCode: response.cep,
			neighboorhood: response.bairro,
			city: response.localidade,
			state: response.uf
		}
	}
}
