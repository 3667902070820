<div class="container">
	<form [formGroup]="form" class="form" (submit)="submit()" novalidate>
		<div class="fade-up-animation">
			<h1>Defina sua senha.</h1>
			<h3>
				Para manter sua conta segura, defina uma senha de acesso ao nosso
				sistema.
			</h3>
			<div class="input-container">
				<label>Senha *</label>
				<anota-input-password
					formControlName="password"
					[placeholder]="'Digite sua senha'"
					[messageError]="passwordError"
				></anota-input-password>
				<br />
			</div>
			<div class="input-container">
				<label>Confirmar senha *</label>
				<anota-input-password
					formControlName="passwordConfirmation"
					[placeholder]="'Confirme sua senha'"
					[messageError]="passwordConfirmationError"
				></anota-input-password>
				<br />
			</div>
			<div class="buttons">
				<anota-button size="lg" [disabled]="form.invalid">Próximo</anota-button>
				<anota-button
				size="lg"
				type="outline-primary"
				*ngIf="renderButton"
				(click)="goBack()"
				>Voltar</anota-button
				>
			</div>
		</div>
	</form>
</div>
