import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidepanelService } from 'src/app/sidepanel/sidepanel.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { FreemiumApiService } from 'src/app/shared/services/freemium-api/freemium-api.service';
import { HotToastService } from '@ngneat/hot-toast';
import { CookieService } from 'ngx-cookie-service';
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';

@Component({
  selector: 'app-vitrine',
  templateUrl: './vitrine.component.html',
  styleUrls: ['./vitrine.component.scss']
})
export class VitrineComponent implements OnInit {

  public merchant_id: any = localStorage.getItem('merchant_id');

  public form: FormGroup;

  public storageForm: any = localStorage.getItem('form');

  public passwordError = ''

  public loading: boolean = false

  constructor(
    private sidepanelService: SidepanelService,
    private freemiumService: FreemiumApiService,
    private toast: HotToastService,
    private cookieService: CookieService,
    private trackerService: TrackersService,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService
    ) { }

  ngOnInit(): void {
    this.sidepanelService.changeToVitrine(true);

    this.merchant_id = this.route.snapshot.queryParamMap.get('merchant_id');
    localStorage.setItem('merchant_id', this.merchant_id);

    this.initForm();
      
  }


  initForm() {
    const storageForm = this.storageService.getForm();
    this.form = new FormGroup({
			first_name: new FormControl(storageForm.first_name ?? '', [Validators.required]),
			email: new FormControl(storageForm.email ?? '', [
				Validators.required,
				Validators.email
			]),
			phone: new FormControl(storageForm.phone ?? '', [
				Validators.required,
				Validators.minLength(11),
				Validators.maxLength(11)
			]),
      password: new FormControl(storageForm.password ?? '', [
        Validators.required,
        Validators.minLength(6)
      ]),
      merchant_id: new FormControl(this.merchant_id, [
        Validators.required
      ])
    });

  }

  submit() {
    this.trackerService.track('VITRINE-CRM-FORM-SUBMIT')
    if(this.form.valid){
      this.loaderOn(true);
      this.loaderService.show('Carregando...')
      this.form.addControl('origin', new FormControl('IFOOD-LP-TRIAL-CRM'))
      this.freemiumService.setVitrineTrial(this.form.value).subscribe((res:any)=>{
        if(res.success){
          const $crawlerObs = this.freemiumService
					.getCompanyImportStatus(res.data.menuId)
					.subscribe((res: any) => {
            this.loaderService.show(res.data.message)
						if (res.data.state === 'error') {
							this.form.enable()
							$crawlerObs.unsubscribe()
              this.loaderOn(false)
              this.loaderService.hide()
							this.toast.error(res.data.message)

							return
						}
						if (res.data.state === 'completed') {
							$crawlerObs.unsubscribe()

							let expiryDate = new Date()
							expiryDate.setMonth(expiryDate.getMonth() + 2)

							this.cookieService.set(
								'ADMIN_TOKEN',
								JSON.stringify(res.data.token),
								expiryDate,
								'/'
							)
							this.trackerService.track('VITRINE-CRM-IMPORTED')
							// this.importationType === 'Link'
							// 	? this.crmApiService
							// 			.updateDealStep(this.globalDealId, 'ifood-imported')
							// 			.subscribe()
							// 	: this.crmApiService
							// 			.updateDealStep(this.globalDealId, 'cnpj-imported')
							// 			.subscribe()
							// this.importationType === 'Link'
							// 	? this.mixPanelService.track(
							// 			'ifood-link-imported',
							// 			this.analyticsId
							// 	  )
							// 	: this.mixPanelService.track('cnpj-imported', this.analyticsId)
              this.loaderOn(false)
              this.loaderService.hide()
							this.router.navigate(['/ifood/download'], {
                queryParamsHandling: 'preserve'
							})
							return
						}
					})
        } else {
          this.loaderOn(false)
          this.loaderService.hide()
          this.toast.error(res.message)
        }
      }, (err)=>{
        this.loaderOn(false)
        this.loaderService.hide()
        this.toast.error(err.error.message)
        console.log(err)
      })
    } else {
      this.toast.error('Complete os dados corretamente!')
    }
  }

  loaderOn(val: boolean): void {
		this.loading = val
	}

}
