import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThanksComponent } from '../thanks/thanks.component';
import { VitrineComponent } from './vitrine.component';

const routes: Routes = [
    {
        path: 'account-info',
        component: VitrineComponent
    },
    {
        path: 'download',
        component: ThanksComponent
    },
    {
        path: '**',
        redirectTo: 'account-info'
    }
]


@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class VitrineRoutingModule {} 