<div class="container">
	<form class="form" [formGroup]="form" (submit)="submit()" novalidate>
		<h1>Seu estabelecimento está cadastrado no iFood?</h1>
		<h3 class="subtitle">
			Podemos fazer a migração do seu cardápio para que você não precise
			cadastrar manualmente.
		</h3>
		<div class="buttons">
			<mat-button-toggle-group multiple="false" class="buttons">
				<mat-button-toggle [ngClass]="haveMenu === 'Sim'? '': notSelectedHaveMenu"
					(click)="setHaveMenu('Sim')">Sim</mat-button-toggle>
				<mat-button-toggle [ngClass]="haveMenu === 'Não'? '': notSelectedHaveMenu"
					(click)="setHaveMenu('Não')">Não</mat-button-toggle>
			</mat-button-toggle-group>
			<br />
		</div>

		<div class="want-to-import" *ngIf="haveMenu === 'Sim'">
			<h2>Como você deseja importar seu cardápio?</h2>
			<div class="buttons">
				<mat-button-toggle-group multiple="false">
					<mat-button-toggle [ngClass]="importationType === 'Link'? '': notSelectedImportationType"
						(click)="setImportationType('Link')">
						<div class="button-content">Pelo link do meu cardápio</div>
					</mat-button-toggle>
					<mat-button-toggle [ngClass]="importationType === 'CNPJ'? '': notSelectedImportationType"
						(click)="setImportationType('CNPJ')">
						<div class="button-content">
							Digitando o CNPJ do meu estabelecimento
						</div>
					</mat-button-toggle>
					<mat-button-toggle [ngClass]="importationType === 'Manualmente'? '': notSelectedImportationType"
						(click)="setImportationType('Manualmente')">
						<div class="button-content">Não desejo importar meu cardápio</div>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
			<br />
		</div>

		<div class="link-import" *ngIf="importationType === 'Link'">
			<h1>Para finalizar, cole o link do seu cardápio iFood abaixo</h1>
			<span class="helper">
				<a (click)="toggleModal()"><i>Onde consigo o link da minha loja do iFood?</i></a>
			</span>
			<div class="modal-container" *ngIf="modalOpen">
				<span (click)="toggleModal()"><i class="fa fa-close"></i></span>
				<div class="modal-header">
					<h3>Aprenda como copiar o link do seu cardápio iFood!</h3>
				</div>
				<div class="modal-body">
					<iframe src="https://www.youtube.com/embed/XsxsaLH3MmI" frameborder="0" allowfullscreen
						autoplay></iframe>
				</div>
				<div class="modal-footer">
					<anota-button [type]="'outline-primary'" (click)="toggleModal()">Fechar vídeo</anota-button>
				</div>
				<br />
			</div>

			<div class="input-container">
				<label>Link do cardápio iFood *</label>
				<anota-input-text [messageError]="invalidLink"
					[placeholder]="'https://www.ifood.com.br/delivery/pelotas-rs/nosotros-centro/...'"
					formControlName="ifoodUrl"></anota-input-text>
			</div>
			<br />
		</div>

		<div class="cnpj-import" *ngIf="importationType === 'CNPJ'">
			<h1>Para finalizar, digite o CNPJ do seu estabelecimento</h1>
			<div class="input-container">
				<label>CNPJ *</label>
				<anota-input-text (keyup)="getEstablishmentsByCnpj()" [mask]="'cnpj'"
					[placeholder]="'00.000.000/0000-00'" formControlName="cnpj"></anota-input-text>
			</div>
			<br />
		</div>

		<div class="more-than-one" *ngIf="foundEstablishment.data?.length > 1">
			<h1>Encontramos mais de um restaurante</h1>
			<h3>Selecione o restaurante desejado</h3>
			<mat-button-toggle-group multiple="false">
				<mat-button-toggle *ngFor="let option of foundEstablishment.data"
					[ngClass]="chosenEstablishment === option? '': notSelectedEstablishment" [title]="option.name"
					[value]="option" (click)="selectEstablishment(option)" layout="row" layout-align="start">
					<div class="button-content">
						<span>
							{{ (option.name.length>12)? (option.name |
							slice:0:12)+'...':(option.name) }}
						</span>
						<img [src]="option.logoUrl" />
					</div>
				</mat-button-toggle>
				<br />
			</mat-button-toggle-group>
		</div>

		<div class="end-buttons" *ngIf="importationType">
			<anota-button size="lg" [disabled]="enableSubmit()" *ngIf="!loading">Finalizar cadastro</anota-button>
			<anota-button [disabled]="true" *ngIf="loading"><svg class="spinner" viewBox="0 0 50 50">
					<circle class="path" cx="25" cy="25" r="10" fill="none" stroke-width="5"></circle>
				</svg></anota-button>
			<anota-button size="lg" type="outline-primary" (click)="goBack()">Voltar</anota-button>
		</div>
	</form>
</div>