import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core'

@Component({
	selector: 'app-accordian',
	templateUrl: './accordian.component.html',
	styleUrls: ['./accordian.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AccordianComponent implements OnInit {
	@Input()
	isOpen = true

	@Input()
	icon: string

	@Input()
	title: string

	@Input()
	subtitle: string

	@Output()
	toggle: EventEmitter<any> = new EventEmitter<any>()

	@Input()
	showArrow: boolean = true

	constructor() {}

	ngOnInit() {}

	public onToogle() {
		if (this.showArrow) {
			this.isOpen = !this.isOpen
		}
	}
}
