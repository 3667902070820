<div class="container">
	<form [formGroup]="form" class="form" (submit)="submit()" novalidate>
		<div [ngClass]="firstDiv">
			<h1>Nos conte sobre o seu delivery</h1>
			<br />
			<h2>Como podemos chamar seu estabelecimento?</h2>
			<h3>
				Esse normalmente é o nome que você utiliza nas suas redes sociais e
				cardápio.
			</h3>
			<div class="input-container">
				<label>Nome do estabelecimento *</label>
				<anota-input-text [placeholder]="'Ex.: Hamburgueria do Pedro'" formControlName="company"
					(keyup)="getCompany()"></anota-input-text>
				<br />
			</div>

			<div *ngIf="!isWaiter">
				<h2>Quantos pedidos você recebe por dia?</h2>
				<h3>
					Isso não mudará nosso período de avaliação, mas nos ajudará a fornecer
					os materiais certos para que você aumente seu número de pedidos.
				</h3>
				<mat-button-toggle-group multiple="false" [value]="selectedOption">
					<mat-button-toggle *ngFor="let sale of salesOptions"
						[ngClass]="sale.value !== selectedOption? optionNotSelected : ''" [value]="sale.value"
						(click)="selectOption(sale.value)">{{sale.label}}</mat-button-toggle>
					<br />
				</mat-button-toggle-group>
				<br />
				<h2>Você possui computador?</h2>
				<h3>
					É recomendado um computador ou um notebook para ativar seu robozinho.
					Caso não possua, não se preocupe, também conseguimos te ajudar com
					nosso aplicativo de celular.
				</h3>
				<mat-button-toggle-group class="pc" multiple="false" [value]="computerSelectedOption.value">
					<mat-button-toggle *ngFor="let computer of computerOptions"
						[ngClass]="computer.value !== computerSelectedOption.value? pcNotSelected : ''"
						[value]="computer.value"
						(click)="selectComputer(computer)">{{computer.label}}</mat-button-toggle>
				</mat-button-toggle-group>
				<br />
			</div>
			<ng-container *ngIf="isWaiter">
				<app-waiter [form]="form" (nextStep)="displaysSecondDiv()"></app-waiter>
			</ng-container>

		</div>
		<div id="second-div">
			<h1>Precisamos saber um pouco sobre você</h1>
			<h3>
				Seus dados são totalmente criptografados. Utilizaremos eles para nos
				comunicarmos melhor durante sua jornada.
			</h3>
			<div class="input-container">
				<label>Seu nome *</label>
				<anota-input-text [placeholder]="'Ex.: João da Silva'" formControlName="name"></anota-input-text>
			</div>
			<div class="input-container">
				<label>Telefone *</label>
				<anota-input-text [mask]="'cel'" [placeholder]="'(99) 99999-9999'"
					formControlName="phone"></anota-input-text>
			</div>
			<div class="input-container">
				<label>Email *</label>
				<anota-input-text [placeholder]="'email@anota.ai'" formControlName="email"></anota-input-text>
			</div>
			<div class="button">
				<anota-button [disabled]="form.invalid" *ngIf="!loading">Próximo</anota-button>
				<anota-button [disabled]="true" *ngIf="loading"><svg class="spinner" viewBox="0 0 50 50">
						<circle class="path" cx="25" cy="25" r="15" fill="none" stroke-width="5"></circle>
					</svg></anota-button>
			</div>
		</div>
	</form>
</div>