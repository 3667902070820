import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-bot-mobile-one-link',
	templateUrl: './bot-mobile-one-link.component.html',
	styleUrls: ['./bot-mobile-one-link.component.scss']
})
export class BotMobileOneLinkComponent implements OnInit {
	goToAppButton = false
	constructor() {}

	ngOnInit(): void {
		setTimeout(() => {
			this.redirectToCorrectLink()
		}, 3000)
		setTimeout(() => {
			this.goToAppButton = true
		}, 4000)
	}

	redirectToCorrectLink() {
		const ua = navigator.userAgent
		const isAndroid = /Android/i.test(ua)

		if (isAndroid) {
			window.location.href = 'https://encurta.anota.ai/aplicativo'
		} else {
			window.location.href =
				'https://play.google.com/store/apps/details?id=ai.anota.android'
		}
	}
}
