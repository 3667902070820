import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Navigation } from '../../interfaces/navigation'

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	public currentStep: Navigation = this.getNavigation()[0]
	public navigationBottomLimit = 1
	constructor(private router: Router) {
		setTimeout(() => {
			this.currentStep = this.getNavigationByPathEnd()
		}, 100)
	}

	public navigateToStep(step: number): void {
		// if (step < this.navigationBottomLimit) {
		//   this.navigationBottomLimit = step;
		// }
		const navigation = this.getNavigationByStep(step)
		this.currentStep = navigation
		this.router.navigate([navigation.path], {
			queryParamsHandling: 'preserve'
		})
	}

	public getNavigationByStep(step: number): Navigation {
		return this.getNavigation().find((navigation) => navigation.step === step)
	}

	public getNavigation(): Navigation[] {
		return [
			{
				path: 'informations',
				title: 'Informações da Conta',
				step: 1,
				display: true
			},
			{ path: 'credentials', title: 'Credenciais', step: 2, display: true },
			{
				path: 'importation',
				title: 'Importar Cardápio',
				step: 3,
				display: true
			},
			{
				path: 'manual',
				title: 'Preenchimento Manual',
				step: 4,
				display: false
			},
			{ path: 'thanks', title: 'Obrigado', step: 5, display: false }
		]
	}

	public getPreviousStep(): Navigation {
		const previousStep = this.currentStep.step - 1
		return this.getNavigationByStep(previousStep)
	}

	public getNextStep(): Navigation {
		const nextStep = this.currentStep.step + 1
		return this.getNavigationByStep(nextStep)
	}

	public navigateToPreviousStep(): void {
		const previousStep = this.getPreviousStep()
		// if (previousStep.step < this.navigationBottomLimit) {
		//   return;
		// }
		this.navigateToStep(previousStep.step)
	}

	public navigateToNextStep(): void {
		this.navigateToStep(this.getNextStep().step)
	}

	getNavigationByPathEnd(): Navigation {
		let pathEnd = this.router.url.replace('/', '')
		pathEnd = pathEnd.slice(0, pathEnd.indexOf('?'))
		return this.getNavigation().find(
			(navigation) => navigation.path === pathEnd
		)
	}

	navigateToManual() {
		this.currentStep = {
			path: 'manual',
			title: 'Preenchimento Manual',
			step: 4
		}
		this.router.navigate(['manual'], {
			queryParamsHandling: 'preserve'
		})
	}

	goToThanks() {
		this.currentStep = { path: 'thanks', title: 'Obrigado', step: 5 }
		this.router.navigate(['thanks'], {
			queryParamsHandling: 'preserve'
		})
	}
}
