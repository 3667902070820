import { SidepanelService } from './../../sidepanel/sidepanel.service'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Route, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { AppComponent } from 'src/app/app.component'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { FreemiumApiService } from 'src/app/shared/services/freemium-api/freemium-api.service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UtmsService } from 'src/app/shared/services/utms/utms.service'
import { SidepanelComponent } from 'src/app/sidepanel/sidepanel.component'
import { environment } from 'src/environments/environment'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { HttpClient } from '@angular/common/http'
import _ from 'lodash'
import { CookieService } from 'ngx-cookie-service'

@Component({
	selector: 'app-informations',
	templateUrl: './informations.component.html',
	styleUrls: ['./informations.component.scss']
})
export class InformationsComponent implements OnInit {
	public firstDiv
	public secondDiv
	public salesOptions = [
		{
			label: 'Menos de 10',
			value: 'Menos de 10'
		},
		{
			label: 'Mais de 10',
			value: 'Mais de 10'
		}
	]
	public computerOptions = [
		{
			label: 'Sim',
			value: true
		},
		{
			label: 'Não',
			value: false
		}
	]
	public computerSelectedOption = { value: null, label: null }
	public selectedOption = { value: null, label: null }
	public form: FormGroup
	public shareableCode: string
	public referrer: any
	public userEmail: string
	public userAlreadyExists: boolean = false
	public isLoading
	public disableButton: boolean = false
	public loading: boolean = false
	public next = 'Próximo'
	public pcNotSelected = ''
	public optionNotSelected = ''
	public ipAddress: string = ''
	public secondDivOpened: boolean = false
	public debounceInterval: any = setTimeout(() => { }, 0)
	public isWaiter: boolean = false

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private crmApiService: CrmApiService,
		private utmsService: UtmsService,
		private freemiumApiService: FreemiumApiService,
		private storageService: StorageService,
		private toastService: HotToastService,
		private router: Router,
		private loaderService: LoaderService,
		private trackerService: TrackersService,
		private sidepanelService: SidepanelService,
		private mixPanelService: MixPanelService,
		private http: HttpClient,
		private cookieService: CookieService,
	) { }

	ngOnInit(): void {
		this.storageService.getLPData()
		this.sidepanelService.setStep(1)
		this.initForm()
		this.firstDivAction('fade-up-animation')
		this.setShareablecode()
		this.setUtms()
		this.checkDeal()
		this.generateAnalyticsId()
		this.checkIsLp()
		this.setRef()
		this.detectWaiter()
	}

	goUpAnimation() {
		this.firstDivAction('go-away-animation')
	}

	firstDivAction(action) {
		this.firstDiv = action
	}

	getCompany() {
		window.clearTimeout(this.debounceInterval)
		this.debounceInterval = setTimeout(() => {
			this.displaysSecondDiv()
		}, 1500)
	}


	public displaysSecondDiv(debounce = 1) {
		if (this.secondDivOpened) return
		setTimeout(() => {
			const scrollCondition = this.isWaiter ?
			 	this.form.get('waiter_quantity').value && this.form.get('waiter_tables').value 
				: this.form.get('order_day').value && this.form.get('computer').value !== ''

			if (
				this.form.get('company').value &&
				scrollCondition
			) {
				document.getElementById('second-div').style.display = 'flex'
				window.scrollTo(
					document.getElementById('second-div').offsetLeft,
					document.getElementById('second-div').offsetTop
				)
				this.secondDivOpened = true
			}
		}, debounce)
	}


	selectOption(option) {
		this.optionNotSelected = 'not-selected'

		this.displaysSecondDiv()

		this.selectedOption = option
		if (this.form.get('company').value && !this.secondDivOpened) {
			//get class 'pc' and scroll to it
			const pc: any = document.getElementsByClassName('pc')[0]
			window.scrollTo(pc.offsetLeft, pc.offsetTop)

		}
		this.form.get('order_day').setValue(this.selectedOption)
	}

	selectComputer(option) {
		this.pcNotSelected = 'not-selected'
		this.displaysSecondDiv()
		this.computerSelectedOption = option
		this.form.get('computer').setValue(this.computerSelectedOption.value)
	}

	initForm() {
		const storageForm = this.storageService.getForm()
		if (storageForm.order_day) this.selectedOption = storageForm.order_day
		if (storageForm.computer === true || storageForm.computer === false) {
			if (storageForm.computer == true) {
				this.computerSelectedOption.value = true
				this.computerSelectedOption.label = 'Sim'
			} else {
				this.computerSelectedOption.value = false
				this.computerSelectedOption.label = 'Não'
			}
		}

		this.displaysSecondDiv()

		this.form = new FormGroup({
			name: new FormControl(storageForm.name ?? '', [Validators.required]),
			email: new FormControl(storageForm.email ?? '', [
				Validators.required,
				Validators.email
			]),
			phone: new FormControl(storageForm.phone ?? '', [
				Validators.required,
				Validators.minLength(11),
				Validators.maxLength(11)
			]),
			order_day: new FormControl(storageForm.order_day ?? '', [
				Validators.required
			]),
			company: new FormControl(storageForm.company ?? '', [
				Validators.required
			]),
			computer: new FormControl(storageForm.computer ?? '', [
				Validators.required
			]),
			acceptTerms: new FormControl(false, [Validators.required])
		})
	}

	setShareablecode() {
		this.shareableCode = this.route.snapshot.queryParamMap.get('shareableCode')
		if (!this.shareableCode)
			this.shareableCode = sessionStorage.getItem('@anota-ai/initiate/shcode')
		else sessionStorage.setItem('@anota-ai/initiate/shcode', this.shareableCode)
		this.crmApiService.shareableCode = this.shareableCode
	}

	setUtms() {
		this.utmsService.setUtmCookies()
	}

	setRef() {
		let query_param = this.route.snapshot.queryParamMap.get('ref');
		query_param ? this.referrer = query_param : this.referrer = document.referrer;
	}

	async checkDeal() {
		const dealIdOnQuery = this.router.parseUrl(this.router.url).queryParams
			.id_deal

		if (dealIdOnQuery) {
			try {
				await this.crmApiService.getDeal(dealIdOnQuery).subscribe(
					async (res: any) => {
						let tries = 0
						this.storageService.setDeal({ dealId: dealIdOnQuery })
						const wait = (ms) =>
							new Promise((resolve) => setTimeout(resolve, ms))
						while ((!res.data.name || !res.data.contacts) && tries < 3) {
							try {
								this.crmApiService
									.getDeal(dealIdOnQuery)
									.toPromise()
									.then((response: any) => {
										res = response
									})
								await wait(1000)
								tries++
							} catch (err) {
								tries++
							}
						}
						if (!res.data.name || !res.data.contacts) return

						//remove all '_invalidated's substrings from email as it may have multiple
						// 'invalidated' substrings 
						let email = res.data.contacts[0].email
						while (email.includes('_invalidated')) {
							email = email.replace('_invalidated', '')
						}
						res.data.contacts[0].email = email
						this.form.get('email').setValue(email)

						// get 'order_day' value from deal custom fields, this field doesn't have a key
						// so we need to get it by value
						const orderDay = res.data.customFields.find(
							(field) => field.value === '50 a 100' ||
								field.value === 'Menos de 10' ||
								field.value === 'Mais de 10' ||
								field.value === '10 a 20' ||
								field.value === '20 a 30' ||
								field.value === '30 a 40' ||
								field.value === '40 a 50' ||
								field.value === 'Mais de 100'
						)
						// get 'computer' value from deal custom fields, this field doesn't have a key could be 'Sim' or 'Não'
						const computer = res.data.customFields.find(
							(field) => field.value === 'Sim' || field.value === 'Não'
						)
						// treat 'order_day' and 'computer' values to match with the options
						if (orderDay) {
							if (orderDay.value !== 'Menos de 10') {
								orderDay.value = 'Mais de 10'
								this.selectOption('Mais de 10')

							} else {
								orderDay.value = 'Menos de 10'
								this.selectOption('Menos de 10')
							}
						}
						if (computer) {
							if (computer.value === 'Sim') {
								this.computerSelectedOption.label = 'Sim'
								this.computerSelectedOption.value = true
							} else {
								this.computerSelectedOption.label = 'Não'
								this.computerSelectedOption.value = false
							}
							this.selectComputer(this.computerSelectedOption)
						}


						const sessionForm = this.storageService.getForm()
						const utms = this.utmsService.getUtms()

						let data = {
							name: res.data.name,
							first_name: res.data.name,
							phone: res.data.contacts[0].phone,
							email: res.data.contacts[0].email,
							company: res.data.companies[0].name,
							order_day: orderDay ? orderDay.value : '',
							computer: computer ? computer.value : '',
							acceptTerms: this.form.get('acceptTerms').value,
							anotaai_reponde_version: environment.ANOTAAI_RESPONDE_DOWNLOAD_URL,
							analytics_id: sessionForm.analytics_id,
							site_origem: 'Trial Anota AI',
							ipAddress: res.data?.ipAddress,
							ref: this.referrer,
							...utms
						}
						Object.keys(data).forEach((key) =>
							data[key] == null || data[key] == 'undefined' ? delete data[key] : {}
						)

						this.storageService.updateForm(
							data
						)

						//save on /initiate/form

						sessionStorage.setItem('@anota-ai/initiate/form', JSON.stringify(data))

						this.crmApiService.analyticsId = res.data.customFields.find(
							(field) => field.key === environment.UUID_KEY
						).value

						// this.router.navigate(['/credentials'], {
						// 	queryParamsHandling: 'preserve'
						// })
						return
					},
					(err) => {
						this.storageService.setDeal()
					}
				)
			} catch (error) {
				this.storageService.setDeal()
			}
		}
	}

	generateAnalyticsId() {
		let sessionForm = this.storageService.getForm()
		if (sessionForm.analytics_id) return
		this.crmApiService.generateAnalyticsId()
	}

	async submit(fromLP: boolean = false) {
		if (this.form.invalid) return
		this.loaderOn(true)
		this.form.disable()
		const sessionForm = this.storageService.getForm()
		const utms = this.utmsService.getUtms()
		const fbp = this.cookieService.get('_fbp')
		const ttp = this.cookieService.get('_ttp')
		const qa_mode = this.route.snapshot.queryParamMap.get('qa_mode')

		const data = {
			name: this.form.get('name').value,
			first_name: this.form.get('name').value,
			email: this.form.get('email').value,
			phone: this.form.get('phone').value,
			order_day: this.form.get('order_day').value,
			company: this.form.get('company').value,
			computer: this.form.get('computer').value == true ? 'Sim' : 'Não',
			acceptTerms: this.form.get('acceptTerms').value,
			anotaai_reponde_version: environment.ANOTAAI_RESPONDE_DOWNLOAD_URL,
			analytics_id: sessionForm.analytics_id,
			site_origem: 'Trial Anota AI',
			fbp: fbp,
			ttp: ttp,
			ref: this.referrer,
			qa_mode,
			...utms
		}

		Object.keys(data).forEach((key) =>
			data[key] == null || data[key] == 'undefined' ? delete data[key] : {}
		)


		if (!_.isEqual(data, JSON.parse(sessionStorage.getItem('@anota-ai/initiate/form')))) {
			sessionStorage.setItem('@anota-ai/initiate/form', JSON.stringify(data))
		} else {
			this.loaderOn(false)
			this.form.enable()
			this.router.navigate(['/credentials'], {
				queryParamsHandling: 'preserve'
			})
			return
		}


		try {

			// const hasUserAlreadyExists = await this.freemiumApiService.checkEmail(
			// 	this.form.value.email
			// )

			// this.freemiumApiService.checkEmail(this.form.value.email).then((res) => {
			// 	if (res) {
			// 		this.userEmail = this.form.value.email
			// 		this.storageService.accountAlreadyUsed = !res
			// 	} else {
			// 		if (!hasUserAlreadyExists) {
			// 			this.loaderOn(false)
			// 			this.form.enable()
			// 			this.toastService.error(
			// 				'Já existe uma conta cadastrada com este email.\n Nós te enviamos um email com as instruções para acessar sua conta.'
			// 			)
			// 			return
			// 		}
			// 		this.loaderOn(false)
			// 		this.form.enable()
			// 		this.toastService.error(
			// 			'Ocorreu um erro na verificação do email. Tente novamente mais tarde.'
			// 		)
			// 		return
			// 	}
			// })

			// if (!hasUserAlreadyExists) {
			// 	this.form.enable()
			// 	this.form.get('email').setErrors({
			// 		alreadyExists: true
			// 	})
			// 	this.isLoading = false
			// 	this.userAlreadyExists = true
			// 	window.scrollTo(0, 0)
			// 	this.trackerService.track('EMAIL-ALREADY-EXISTS', {
			// 		value: this.form.value.email
			// 	})

			// 	return
			// }
			this.crmApiService.upsertDeal(data, true).subscribe(
				(res: any) => {
					this.storageService.updateForm({
						...this.form.value,
						...this.utmsService.getUtms(),
						ipAddress: res.data?.ipAddress
					})
					this.storageService.setDeal({ dealId: res.data.dealId })
					this.trackerService.track('CREATE-DEAL', data)
					// this.crmApiService
					// 	.updateDealStep(res.data.dealId, 'register')
					// 	.subscribe()
					this.mixPanelService.track(
						'initial-data-sent',
						sessionForm.analytics_id
					)
					this.loaderOn(false)

					if (!fromLP) {
						if (
							this.form.value.computer == 'Não' ||
							this.form.value.order_day == 'Menos de 10'
						) {
							this.trackerService.track('MQL-LEAD-CREATED')
						} else {
							this.trackerService.track('SQL-LEAD-CREATED')
						}
					}
					this.storageService.changeIsLp(false)
					this.router.navigate(['/credentials'], {
						queryParamsHandling: 'preserve'
					})
				},
				(error) => {
					this.loaderOn(false)
					this.toastService.error(
						'Ops! Ocorreu um erro, mas não se preocupe, você receberá informações no seu e-mail e whatsapp.',
						{ duration: 10000 }
					)

					this.disableButton = true
				}
			)
		} catch (err) {
			this.loaderOn(false)
			this.toastService.error('Ops! Ocorreu um erro. Tente novamente!')
			if (data.computer === 'Não') {
				window.location.href =
					`${environment.NO_COMPUTER_LANDBOT}?name=${data.name}&email=${data.email}&phone=${data.phone}&order_day=${data.order_day}&company_name=${data.company}`.replace(
						/\s+/g,
						''
					)
				return
			}
		}

		this.form.enable()
		this.isLoading = false
	}

	loaderOn(val: boolean) {
		this.loading = val
		this.loading ? this.loaderService.show('Estamos efetuando o seu cadastro!') : this.loaderService.hide()
	}

	getIPAddress(): Promise<any> {
		return this.http.get('https://api.ipify.org/?format=json').toPromise()
	}


	checkIsLp() {
		let $isLp = this.storageService.checkIsLp().subscribe((res: any) => {
			if (res) {
				//touch all fields to show errors
				Object.keys(this.form.controls).forEach(field => {
					const control = this.form.get(field);
					control.markAsTouched();
					control.markAsDirty()
				});
				// update validity
				this.form.updateValueAndValidity(
					{ onlySelf: true }
				);
				//remove phone validators
				this.form.get('phone').clearValidators()
				this.form.get('phone').updateValueAndValidity()


				this.submit(true)

				this.form.get('phone').setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11)])
				this.form.get('phone').updateValueAndValidity()
			}
		},
			(err) => {
				console.log(err)
			},
			() => {
				$isLp.unsubscribe()
			}
		)
	}

	detectWaiter() {
		const utms = this.utmsService.getUtms()
		if (utms.utm_source.includes('garçom') || utms.utm_source.includes('garcom')) {
			this.form.get('computer').setValue(false)
			this.form.get('order_day').setValue('Menos de 10')
			this.form.get('computer').clearValidators()
			this.form.get('computer').updateValueAndValidity()
			this.form.get('order_day').clearValidators()
			this.form.get('order_day').updateValueAndValidity()

			this.enableWaiterFields()
		}
	}

	enableWaiterFields() {
		this.form.addControl('waiter_quantity', new FormControl('', [Validators.required]))
		this.form.addControl('waiter_tables', new FormControl('', [Validators.required]))
		this.isWaiter = true
	}

}
