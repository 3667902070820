<div class="container">
    <form [formGroup]="form" class="form" (submit)="submit()" novalidate>
		<div class="fade-up-animation">
			<h1>Precisamos saber um pouco sobre você.</h1>
			<h3>
				Seus dados são totalmente criptografados. Utilizaremos eles para nos comunicarmos
                melhor durante sua jornada.
			</h3>
            <div class="input-container">
				<label>Seu nome *</label>
				<anota-input-text [placeholder]="'Ex.: João da Silva'" formControlName="first_name"></anota-input-text>
			</div>
			<div class="input-container">
				<label>Telefone *</label>
				<anota-input-text [mask]="'cel'" [placeholder]="'(99) 99999-9999'"
					formControlName="phone"></anota-input-text>
			</div>
			<div class="input-container">
				<label>Email *</label>
				<anota-input-text [placeholder]="'email@anota.ai'" formControlName="email"></anota-input-text>
			</div>
			<div class="input-container">
				<label>Senha *</label>
				<anota-input-password
					formControlName="password"
					[placeholder]="'Digite sua senha'"
					[messageError]="passwordError"
				></anota-input-password>
				<br />
			</div>
			<div class="buttons">
				<anota-button size="lg" [disabled]="form.invalid" *ngIf="!loading">Próximo</anota-button>
				<anota-button [disabled]="true" *ngIf="loading"><svg class="spinner" viewBox="0 0 50 50">
					<circle class="path" cx="25" cy="25" r="10" fill="none" stroke-width="5"></circle>
				</svg></anota-button>
			</div>
		</div>
	</form>
</div>
