import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})
export class MixPanelService {
	public analyticsId = undefined
	constructor(private http: HttpClient) {}

	public track(event_name: string, analytics_id: any = this.analyticsId) {
		return console.log('Analytics id events disabled, for now')
		this.http
			.post(environment.MIX_PANEL_TRACKER_URL, { event_name, analytics_id })
			.subscribe()
	}

	public async getUUID() {
		this.http.get(`${environment.CRM_API_URL}/noauth/new-uuid`).subscribe(
			(res: any) => {
				if (res.success) {
					this.analyticsId = res.data

					this.track('register-load')
					return this.analyticsId
				}
			}
		)
	}

	public sendErrorPayload(error: any, analytics_id: any = this.analyticsId) {
		return console.log('Analytics id events disabled, for now')
		this.http
			.post(`${environment.MIX_PANEL_TRACKER_URL}`, {
				event_name: 'freemium_payload_error',
				error,
				analytics_id
			})
			.subscribe()
	}
}
