<div
	class="loader-wrapper"
	[ngStyle]="{'background': whiteBg ? 'white' : 'rgba(0, 0, 0, 0.5)'}"
	*ngIf="isLoading"
>
	<div class="loader-container">
		<app-pulse-loader info=" "></app-pulse-loader>
		<p class="loader-wrapper-message">{{ message }}</p>
	</div>
</div>
