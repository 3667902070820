<div class="container">
	<form class="form" [formGroup]="form" (submit)="submit()" novalidate>
		<h1>Olá novamente!</h1>
		<h2>Digite seu email abaixo</h2>
		<h3>
			Identificamos que você ja se cadastrou previamente. Digite seu email para
			continuar de onde parou.
		</h3>
		<div class="input-container">
			<label for="email">Email *</label>
			<anota-input-text
				formControlName="email"
				placeholder="Email"
				[type]="'email'"
			></anota-input-text>
		</div>
		<br />
		<anota-button size="lg" [disabled]="form.invalid" *ngIf="!loading"
			>Próximo</anota-button
		>
		<anota-button [disabled]="true" *ngIf="loading"
			><svg class="spinner" viewBox="0 0 50 50">
				<circle
					class="path"
					cx="25"
					cy="25"
					r="10"
					fill="none"
					stroke-width="5"
				></circle></svg
		></anota-button>
	</form>
</div>
