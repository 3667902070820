import {
	Component,
	OnInit,
	AfterContentChecked,
	ChangeDetectorRef
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { StorageService } from './shared/services/storage/storage.service'
import { TrackersService } from './shared/services/trackers/trackers.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
	title = 'register-frontend'
	public step = 1
	public showManual = false
	public show = true
	constructor(
		private router: Router,
		private gtmService: GoogleTagManagerService,
		private cdr: ChangeDetectorRef,
		private trackers: TrackersService
	) {}
	ngOnInit(): void {
		this.trackers.initTrackers()
		this.router.events.forEach((event) => {
			if (event instanceof NavigationEnd) {
				this.gtmService.pushTag({
					event: 'page',
					pageName: event.url
				})

				if (event.url.match(/thanks|next-step|email-sent/)) {
					this.show = false
				} else {
					this.show = true
				}
			}
		})
	}

	ngAfterContentChecked(): void {
		this.cdr.detectChanges()
	}
}
