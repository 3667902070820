import { SidepanelService } from './../../sidepanel/sidepanel.service'
import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AppComponent } from 'src/app/app.component'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { FreemiumApiService } from 'src/app/shared/services/freemium-api/freemium-api.service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-remarketing',
	templateUrl: './remarketing.component.html',
	styleUrls: ['./remarketing.component.scss']
})
export class RemarketingComponent implements OnInit {
	public form: FormGroup
	public userEmail: string
	public userAlreadyExists: boolean = false
	public isLoading
	public disableButton: boolean = false
	public dealId: string
	public loading: boolean = false

	constructor(
		private crmApiService: CrmApiService,
		private freemiumApiService: FreemiumApiService,
		private storageService: StorageService,
		private router: Router,
		private loaderService: LoaderService,
		private sidepanelService: SidepanelService
	) {}

	ngOnInit(): void {
		this.initForm()
		this.sidepanelService.setStep(1)
	}

	initForm() {
		this.form = new FormGroup({
			name: new FormControl(''),
			email: new FormControl('', [Validators.required, Validators.email]),
			phone: new FormControl(''),
			order_day: new FormControl(''),
			company: new FormControl(''),
			computer: new FormControl(''),
			acceptTerms: new FormControl(false)
		})
	}

	submit() {
		this.populateForm()
	}

	async populateForm() {
		this.loaderOn(true)
		const response = await this.freemiumApiService.checkEmailRemarket(
			this.form.value.email
		)
		if (response.success) {
			if (response.data) {
				this.dealId = response.data.deal._id
				if (
					response.data.deal.customFields.find(
						(field) => field.key === environment.UUID_KEY
					)
				) {
					this.crmApiService.analyticsId = response.data.deal.customFields.find(
						(field) => field.key === environment.UUID_KEY
					).value
				}
				this.storageService.updateForm({
					name: response.data.deal.name,
					phone: response.data.deal.contacts[0].phone,
					email: response.data.deal.contacts[0].email,
					company: response.data.deal.companies[0].name
				})
				this.loaderOn(false)
				this.router.navigate(['/informations'], {
					queryParamsHandling: 'preserve'
				})
			} else {
				this.storageService.updateForm({
					email: this.form.value.email
				})
				this.loaderOn(false)
				this.router.navigate(['/informations'], {
					queryParamsHandling: 'preserve'
				})
			}
		} else {
			this.loaderOn(false)
			this.userEmail = this.form.value.email
			this.userAlreadyExists = true
		}
	}

	loaderOn(value) {
		this.isLoading = value
	}
}
