import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VitrineComponent } from './vitrine.component';
import { VitrineRoutingModule } from './vitrine-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from 'src/app/shared/components/input/input.component';
import { DsModule } from '@githubanotaai/design-system';




@NgModule({
  declarations: [
    VitrineComponent,
  ],
  imports: [
    CommonModule,
    VitrineRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DsModule
  ]
})
export class VitrineModule { }
