import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { AnotaaiDesktopService } from 'src/app/shared/services/anotaai-desktop/anotaai-desktop.service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { environment } from 'src/environments/environment'

const ANOTAAI_RESPONDE_DOWNLOAD_URL = environment.ANOTAAI_RESPONDE_DOWNLOAD_URL

@Component({
	selector: 'app-anotaai-desktop-supported-os',
	templateUrl: './anotaai-desktop-supported-os.component.html',
	styleUrls: ['./anotaai-desktop-supported-os.component.scss']
})
export class AnotaaiDesktopSupportedOsComponent implements OnInit {
	public isMobileBrowser = false
	constructor(
		private anotaaiDesktopService: AnotaaiDesktopService,
		private router: Router,
		private toast: HotToastService,
		private cookieService: CookieService,
		private route: ActivatedRoute,
		private trackersService: TrackersService,
		private storageService: StorageService
	) {}

	ngOnInit(): void {
		if (sessionStorage.getItem('downloadStarted') != 'true') {
			this.downloadAnotaAI()
		}
		const token = this.route.snapshot.queryParamMap.get('token')
		if (token) {
			let expiryDate = new Date()
			expiryDate.setMonth(expiryDate.getMonth() + 2)
			this.cookieService.set(
				'ADMIN_TOKEN',
				JSON.stringify(token),
				expiryDate,
				'/'
			)
		}
		this.indentifyApplication()
	}

	downloadAnotaAI() {
		let sessionForm = this.storageService.getForm()
		window.open(ANOTAAI_RESPONDE_DOWNLOAD_URL, '_parent')
		sessionStorage.setItem('downloadStarted', 'true')
		this.trackersService.track('DOWNLOAD-ANOTAAI', sessionForm)
	}

	indentifyApplication() {
		this.anotaaiDesktopService.verifyAnotaaiDesktop().subscribe((res) => {
			this.toast.success('Aplicação instalada com sucesso!', { duration: 5000 })
			this.toast.loading('Vamos continuar para o login...', { duration: 5000 })
			setTimeout(() => {
				this.router.navigate(['/next-step'])
			}, 5000)
		})
	}
}
