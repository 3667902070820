<div class="starter-guide-grid-item">
	<button
		class="starter-guide-grid-item-trigger"
		style="cursor: default"
		(click)="onToogle()"
	>
		<i [class]="icon"></i>
		<div>
			<h1>{{ title }}</h1>
			<p>{{ subtitle }}</p>
		</div>
		<i
			[ngClass]="(isOpen) ? 'icon-arrow-up':'icon-arrow-down'"
			*ngIf="showArrow"
		></i>
	</button>
	<div
		[ngClass]="(isOpen) ? 'starter-guide-grid-item-content active':'starter-guide-grid-item-content'"
	>
		<ng-content></ng-content>
	</div>
</div>
