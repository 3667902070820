import { Component, OnInit } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'

@Component({
	selector: 'app-bot-mobile-unsupported-os',
	templateUrl: './bot-mobile-unsupported-os.component.html',
	styleUrls: ['./bot-mobile-unsupported-os.component.scss']
})
export class BotMobileUnsupportedOsComponent implements OnInit {
	constructor(
		private trackerService: TrackersService,
		private toast: HotToastService
	) {}

	ngOnInit(): void {
		this.sendMessage()
	}

	sendMessage() {
		this.trackerService.track('SEND-MESSAGE-CLICK-THANKS-PAGE')
		this.toast.success('Enviamos os próximos passos para o seu email', {
			duration: 5000
		})
	}
}
