import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UtmsService } from 'src/app/shared/services/utms/utms.service'

@Component({
	selector: 'app-thanks',
	templateUrl: './thanks.component.html',
	styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
	public cases = {
		desksupos: {
			id: 'desksupos',
			title: 'Anota AI Desktop Supported OS',
			condition: true
		},
		deskunsupos: {
			id: 'deskunsupos',
			title: 'Anota AI Desktop Unsupported OS',
			condition: false
		},
		botmobilesupos: {
			id: 'botmobilesupos',
			title: 'Bot Mobile Supported OS',
			condition: false
		},
		botmobileunsupos: {
			id: 'botmobileunsupos',
			title: 'Bot Mobile Unsupported OS',
			condition: false
		},
		botmobileonelink: {
			id: 'botmobileonelink',
			title: 'Bot Mobile Redirect',
			condition: false
		}
	}

	constructor(
		private router: Router,
		private utmsService: UtmsService,
		private trackerService: TrackersService,
		private cookieService: CookieService
	) {}

	ngOnInit(): void {
		this.getLoginToken()
		this.caseSelect()
		this.trackerService.track('FINISH')
	}

	changeCase(id: string) {
		Object.keys(this.cases).forEach((key) => {
			this.cases[key].id == id
				? (this.cases[key].condition = true)
				: (this.cases[key].condition = false)
		})
	}

	caseSelect() {
		const utms = this.utmsService.getUtms()
		const ua = navigator.userAgent
		const isAndroid = /Android/i.test(ua)
		const isWindows = /Windows/i.test(ua)

		const botMobileOneLink = this.router.parseUrl(this.router.url).queryParams
			.onelink

		if (botMobileOneLink) {
			if (isAndroid || isWindows) {
				this.changeCase('botmobileonelink')
			} else {
				this.changeCase('botmobileunsupos')
			}
			return
		}

		const application =
			this.router.parseUrl(this.router.url).queryParams.application ||
			utms.application

		if (application == 'BOT_MOBILE') {
			if (isAndroid) {
				this.changeCase('botmobilesupos')
			} else {
				this.changeCase('botmobileunsupos')
			}
		} else {
			if (isWindows) {
				this.changeCase('desksupos')
			} else {
				this.changeCase('deskunsupos')
			}
		}
	}

	getLoginToken() {
		// const token = this.router.parseUrl(this.router.url).queryParams.admin_token
		const token =
			this.router.parseUrl(this.router.url).queryParams.admin_token ||
			this.router.parseUrl(this.router.url).queryParams.token
		if (token) {
			this.router.parseUrl(this.router.url).queryParams.admin_token && this.trackerService.track('CLICKED-ADMIN-TOKEN-FROM-EMAIL')
			this.cookieService.delete('ADMIN_TOKEN', '/')
			let expiryDate = new Date()
			expiryDate.setMonth(expiryDate.getMonth() + 2)
			this.cookieService.set(
				'ADMIN_TOKEN',
				JSON.stringify(token),
				expiryDate,
				'/'
			)
		}
	}
}
