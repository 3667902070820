<main class="email-screen">
    <section>
        <h2>Parece que você já possui uma conta na AnotaAI! 😀</h2>
        <p>
            Enviamos um email para você com um link para fazer que você possa fazer login.
           <br>
           Acesse o seu email para continuar. 
        </p>
        <app-pulse-loader info="Você já pode fechar essa página."></app-pulse-loader>
        <br />
    </section>
</main>
