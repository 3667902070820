import { Component, OnInit } from '@angular/core'
import { UtmsService } from 'src/app/shared/services/utms/utms.service'

@Component({
	selector: 'app-email-sent',
	templateUrl: './email-sent.component.html',
	styleUrls: ['./email-sent.component.scss']
})
export class EmailSentComponent implements OnInit {
	constructor(private utms: UtmsService) {}

	ngOnInit(): void {
		const utms = this.utms.getUtms()
		if (
			utms.redirect &&
			utms.redirect != 'undefined' &&
			utms.redirect != '' &&
			utms.redirect != 'null' &&
			utms.redirect != '/'
		) {
			console.log('Rdirecting to: ', utms.redirect)
			window.location.href = utms.redirect
			return
		}
	}
}
