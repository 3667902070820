import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { timer } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})
export class FreemiumApiService {
	private baseUrl = environment.BACKEND_URL
	constructor(private httpClient: HttpClient) {}

	async checkEmail(email: string) {
		try {
			const { success } = await this.httpClient
				.get<any>(
					`${this.baseUrl}/noauth/freemium/validate-email?email=${email}`
				)
				.toPromise()

			return success
		} catch (error) {
			return false
		}
	}

	async checkEmailRemarket(email: string) {
		try {
			const res = await this.httpClient
				.get<any>(
					`${this.baseUrl}/noauth/freemium/validate-email?email=${email}`
				)
				.toPromise()
			return res
		} catch (error) {
			return { success: false, data: null }
		}
	}

	createCompany(company: any) {
		return this.httpClient.post(`${this.baseUrl}/noauth/freemium`, company)
	}

	getCompanyImportStatus(importId: string) {
		return timer(1, 3000).pipe(
			switchMap(() =>
				this.httpClient.get(`${this.baseUrl}/noauth/freemium/menu/${importId}`)
			)
		)
	}

	getByCNPJ(cnpj) {
		const encodedCNPJ = encodeURIComponent(cnpj)
		const url = `${this.baseUrl}/noauth/ifood/get-merchant-menu-by-cnpj/${encodedCNPJ}`
		return this.httpClient.get(url)
	}

	setVitrineTrial(element){  
		const url = `${this.baseUrl}/noauth/ifood/register-trial`
		return this.httpClient.post(url, element); 
	}
}
